import { navigate } from 'gatsby';
import React from 'react';

const NotFound = () => {
  if (typeof window !== 'undefined') {
    void navigate('/');
  }

  return <React.Fragment />;
};

//eslint-disable-next-line import/no-default-export
export default NotFound;
